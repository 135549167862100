
import Vue from "vue";
import axios from "axios";
import apiUrl from "@/utils/apiUrl";

export default Vue.extend({
  name: "items",
  components: {
    editItem: () => import("../components/editItem.vue"),
  },
  data: () => ({
    search: "",
    items: [],
  }),
  async mounted() {
    const itemReq = await axios.get(`${apiUrl}/api/item/all`);
    this.items = itemReq.data.items;
  },
  methods: {
    openDownload(itemNo: string) {
      window.open(`${window.location.origin}/download/item/${itemNo}`);
    },
    searchFilter(
      value: string,
      search: string,
      item: { no: string; description: string }
    ) {
      if (!search) return true;
      if (item.no.toLowerCase().startsWith(search.toLowerCase())) return true;
      if (item.description.toLowerCase().startsWith(search.toLowerCase()))
        return true;
      return false;
    },
  },
});
